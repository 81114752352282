import { makeAutoObservable } from 'mobx';
import { isEqual, omit } from 'lodash';

class UserStore {
  loggedUser = null;
  checkingJwt = false;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.loggedUser = null;
    this.checkingJwt = false;
  }

  setLoggedUser(userData) {
    if (!this.loggedUser || !isEqual(omit(this.loggedUser, ['language', 'TwoFA']), omit(userData, ['language', 'TwoFA']))) {
      this.loggedUser = userData;
    }
  }

  setCheckingJwt(checkingJwt) {
    this.checkingJwt = checkingJwt;
  }

  get getLoggedUser() {
    return this.loggedUser;
  }

  get getCheckingJwt() {
    return this.checkingJwt;
  }

  logOut() {
    this.loggedUser = null;
  }
}

const userStore = new UserStore();
export default userStore;
